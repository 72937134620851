<template>
    <div>
		<el-dialog :title="title" :visible.sync="open" width="500px" @close="commitOnClose">
			<el-form ref="saveOrEditForm" :model="saveData" label-width="120px">
				<el-form-item label="渠道编码" prop="channelNo">
					<el-input v-model="saveData.channelNo" placeholder="请输入渠道编码" :disabled="!isLook || !isAdd"/>
				</el-form-item>
				<el-form-item label="渠道名称" prop="name">
					<el-input v-model="saveData.name" placeholder="请输入渠道名称" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="渠道代码" prop="code">
					<el-input v-model="saveData.code" placeholder="请输入渠道代码" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="一级代理商编码" prop="oneAgentNo">
					<el-input v-model="saveData.oneAgentNo" placeholder="请输入一级代理商编码" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="是否可用" prop="available">
					<el-switch v-model="saveData.available" active-value="1" inactive-value="0" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="是否验证签名" prop="ignoreSign"> 
					<el-switch v-model="saveData.ignoreSign" active-value="0" inactive-value="1" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="logo图标" prop="logoImgUrl">
					<el-input v-model="saveData.logoImgUrl" placeholder="请输入logo图标" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="签名私钥" prop="secretKey">
					<el-input v-model="saveData.secretKey"  type="textarea" :rows="3" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="上游渠道公钥" prop="publicKey"> 
					<el-input v-model="saveData.publicKey"  type="textarea" :rows="3" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="api请求参数" prop="apiParam">
					<el-input v-model="saveData.apiParam"  type="textarea" :rows="3" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="来源" prop="source">
					<el-input v-model="saveData.source" placeholder="请输入来源" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="请求路径" prop="baseUrl">
					<el-input v-model="saveData.baseUrl" placeholder="请输入请求路径" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="请求路径2" prop="baseUrl2">
					<el-input v-model="saveData.baseUrl2" placeholder="请输入请求路径2" :disabled="!isLook"/>
				</el-form-item>
				<el-form-item label="分润计算方式" prop="profitAllocationType">
					<el-select v-model="saveData.profitAllocationType" clearable style="width: 100%" :disabled="!isLook">
						<el-option v-for="(value,key) in dicts.PROFIT_ALLOCATION" :key="key" :label="value" :value="key"/>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" v-if="isLook" @click="submitForm">{{translate('FROM.SUBMIT')}}</el-button>
				<el-button type="primary" @click="cancel">{{translate('FROM.CANCEL')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {BasicApi} from '@/api';
	export default {
		name: "CustomForm",
		props: {
			dicts: {
				type: Object,
				default: function () {
					return {}
				}
			},
			isAdd: {
				type: Boolean,
				default: true,
			},
			isLook: {
				type: Boolean,
				default: true,
			},
			selectRow: {
				type: Object,
				default: function () {
					return {}
				},
			},
			onFresh: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				title: '',
				saveData: {
					available:'1',
					ignoreSign: '0'
				},
				open: false
			}
		},
		watch: {
			onFresh: function (value) {
				this.open = value;
				if (value) {
					this.resetData();
				}
			}
		},
		mounted() {
		},
		methods: {
			async resetData() {
				if(this.isLook){
					if (this.isAdd){
						this.title = this.translate('FROM.ADD_MODULE', {module: 'MODULE.CONFIG'});
						this.saveData = {
							available:'1',
							ignoreSign: '0'
						};
					}else {
						this.saveData = this.selectRow;
						this.isAdd = false;
						this.title = this.translate('FROM.UPDATE_MODULE', {module: 'MODULE.CONFIG'});
					}
				}else{
					this.saveData = this.selectRow;
					this.isAdd = false;
					this.title = "查看详情"
				}
			},
			async submitForm() {
				let result = (this.isAdd) ? (await BasicApi.acqChannel.save(this.saveData)) : (await BasicApi.acqChannel.queryedit(this.saveData));
				if (result.success) {
					this.Message.success(this.translate(this.isAdd ? 'FROM.ADD_SUCCESS' : 'FROM.UPDATE_SUCCESS'));
					this.commitOnClose();
				}
			},
			cancel() {
				this.saveData = {
					available:'1',
					ignoreSign: '0'
				};
				this.commitOnClose();
			},
			commitOnClose() {
				this.$emit('on-close');
			}
		}
	};
</script>

<style scoped>

</style>
